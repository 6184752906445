import React, {memo} from 'react';
import Image from "./Image";
import {Avatar} from "@mui/material";
import {getAvatarString} from "../../actions/commonActions";

const ProfilePic = ({name, pic, size, type = null}) => {
    const imageStyle = {
        width: size,
        height: size,
    }
    const avatarStyle = {
        height: size,
        width: size,
        fontSize: size / 2
    }
    return (
        <div>
            {
                pic ?
                    <Image src={pic}
                           className={`w-11 h-11 rounded-full`}
                           style={imageStyle}
                           alt=""
                           type={type}/> :
                    <Avatar style={avatarStyle} className={"avatar"}
                            children={getAvatarString(name)}/>
            }
        </div>
    );
};

export default memo(ProfilePic);
