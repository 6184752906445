import moment from "moment";
import { SIEMBA_ADMIN, SIEMBA_CUSTOMER, SIEMBA_CUSTOMER_ENGINEER, SIEMBA_TESTER } from "../../../utils/roles";

export const getUserAvatarDetails = (notification) => {
    const user = {
        first_name: " ",
        profile_picture: "",
        last_name: " "
    };
    switch (notification.type) {
        case "INVITE_USER":
        case "FINDING_CUSTOMER_ASSIGNED":
        case "PENTEST_CREATED":
        case "PENTEST_DRAFTED":
        case "PENTEST_DRAFT_DELETED":
        case "PENTEST_ASSET_ADDED":
        case "PENTEST_MANAGER_ADDED":
        case "PENTEST_MANAGER_REMOVED":
        case "ASSET_POC_ASSIGNED":
        case "ASSET_POC_REMOVED":
        case "PENTEST_ASSET_DELETED":
        case "ASSET_DELETE_REQUEST":
        case "PRE_REQUEST_SHARED":
        case "FINDING_STATUS_UPDATED":
        case "QUALYS_FINDING_STATUS_UPDATED":
        case "FINDING_EDITED":
        case "FINAL_REPORT_GENERATED":
        case "PRE_REQUEST_COMMENT":
        case "PRE_REQUEST_APPROVED":
        case "PENTEST_STATUS_CHANGED":
        case "ASSET_COMPLETION_CHANGED":
        case "ASSET_ASSIGNED":
        case "ASSET_UNASSIGNED":
        case "USER_STORY_ADDED":
        case "USER_STORY_EDITED":
        case "BLOCKER_ADDED":
        case "BLOCKER_STATUS_UPDATED":
        case "BLOCKER_EDITED":
        case "LATEST_STATUS_ADDED":
        case "POTENTIAL_FINDING_ADDED":
        case "OPEN_FINDING_ADDED":
        case "ASSET_STATUS_CHANGED":
        case "PENTEST_COMPLETION_CHANGED":
            user.first_name = notification?.init_user?.first_name;
            user.profile_picture = notification?.init_user?.profile_picture;
            break;
        case "FILL_PRE_REQUEST":
        case "PENTEST_NEXT_ITERATION":
        case "FILL_PREREQ_REMINDER":
        case "IMPORTED_ASSETS":
            user.first_name = notification?.init_user?.first_name;
            user.last_name = notification?.init_user?.last_name;
            break;
        case "FILL_PREREQ_REQUEST":
            user.profile_picture = "APP_LOGO";
            break;
        default:
            user.profile_picture = "APP_LOGO";
            break;
    }

    return user;
}


export const getNotificationAction = (notification, mainUrl) => {
    const action = {
        type: "",
        url: "",
        id: "",
        assetId: "",
        pentestId: "",
        modalType: "",
        data: ""
    }


    switch (notification.type) {
        case "INVITE_USER":
        case "PENTEST_CREATED":
        case "PENTEST_STATUS_CHANGED":
        case "PENTEST_COMPLETION_CHANGED":
        case "PENTEST_NEXT_ITERATION":
        case "FINDING_CUSTOMER_ASSIGNED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/pentests/all_findings/${notification?.finding?.id}/dashboard`;
            action.id = notification?.finding?.id;
            break;
        case "PENTEST_ASSET_DELETED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_pentests/${notification?.pentest?.id}/dashboard`;
            action.id = notification?.pentest?.id;
            break;
        case "PENTEST_DRAFTED":
        case "PENTEST_DRAFT_DELETED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/pentests/draft`;
            action.id = notification?.pentest?.id;
            break;
        case "PENTEST_ASSET_ADDED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_pentests/${notification?.pentest?.id}/dashboard`;
            action.id = notification?.pentest?.id;
            break;
        case "PENTEST_MANAGER_ADDED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_pentests/${notification?.pentest?.id}/dashboard`;
            action.id = notification?.pentest?.id;
            break;
        case "PENTEST_MANAGER_REMOVED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_pentests/${notification?.pentest?.id}/dashboard`;
            action.id = notification?.pentest?.id;
            break;
        case "ASSET_POC_ASSIGNED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_pentests/${notification?.pentest?.id}/dashboard`;
            action.id = notification?.pentest?.id;
            break;
        case "ASSET_POC_REMOVED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_pentests/${notification?.pentest?.id}/dashboard`;
            action.id = notification?.pentest?.id;
            break;
        case "ASSET_DELETE_REQUEST":
        case "FILL_PRE_REQUEST":
        case "PRE_REQUEST_APPROVED":
        case "ASSET_COMPLETION_CHANGED":
        case "ASSET_ASSIGNED":
        case "ASSET_UNASSIGNED":
        case "LATEST_STATUS_ADDED":
        case "ASSET_STATUS_CHANGED":
        case "FILL_PREREQ_REMINDER":
        case "FILL_PREREQ_REQUEST":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_assets/${notification?.pentest_asset?.id}/dashboard`;
            action.id = notification?.pentest_asset?.id;
            break;
        case "PRE_REQUEST_SHARED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_assets/${notification?.pentest_asset?.id}/pre_requisites`;
            action.id = notification?.pentest_asset?.id;
            break;
        case "PRE_REQUEST_COMMENT":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_assets/${notification?.pentest_asset?.id}/pre_requisites?question=${notification?.question_id}&category=${notification?.category_type}`;
            action.id = notification?.pentest_asset?.id;
            break;
        case "USER_STORY_ADDED":
        case "USER_STORY_EDITED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/all_assets/${notification?.pentest_asset?.id}/asset_desc_and_user_stories`;
            action.id = notification?.pentest_asset?.id;
            break;
        case "POTENTIAL_FINDING_ADDED":
        case "OPEN_FINDING_ADDED":
        case "FINDING_STATUS_UPDATED":
        case "FINDING_EDITED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/pentests/all_findings/${notification?.finding?.id}/dashboard`;
            action.id = notification?.finding?.id;
            break;
        case "QUALYS_FINDING_STATUS_UPDATED":
            action.type = "NAVIGATE";
            action.url = `${mainUrl}/va/all_findings/${notification?.va_id}/dashboard?assetId=${notification?.asset_id}`;
            action.id = notification?.va_id;
            break;
        case "BLOCKER_ADDED":
        case "BLOCKER_STATUS_UPDATED":
        case "BLOCKER_EDITED":
            action.type = "OPEN_MODAL";
            action.assetId = notification?.pentest_asset?.id;
            action.id = notification?.blocker?.id;
            action.url = `${mainUrl}/all_assets/${notification?.pentest_asset?.id}/dashboard`;
            action.modalType = "BLOCKER";
            break;
        case "FINAL_REPORT_GENERATED":
            action.type = "OPEN_MODAL";
            action.assetId = notification?.pentest_asset?.id;
            action.pentestId = notification?.pentest?.id;
            const report = notification?.report;
            report.generated_file = report?.url;
            report.key = report?.report_type;
            report.is_report_generated = true;
            report.title = reportNames[report?.report_type];
            action.data = report;
            action.modalType = "REPORT";
            break;
        case "IMPORTED_ASSETS":
        case "NOTIFY_PREREQUISITES":
        default:
            return ""
    }

    return action;
}


export const getMainURL = (userRole) => {
    let mainUrl = "/app";
    if (userRole === SIEMBA_ADMIN) {
        mainUrl = "/admin";
    } else if (userRole === SIEMBA_TESTER) {
        mainUrl = "/tester";
    } else if (userRole === SIEMBA_CUSTOMER) {
        mainUrl = "/app";
    } else if (userRole === SIEMBA_CUSTOMER_ENGINEER) {
        mainUrl = "/app";
    }
    if (!userRole) {
        mainUrl = "/";
    }
    return mainUrl;
}

export const getNotificationTime = (date) => {
    try {
        if (date) {
            return moment(date).fromNow(true);
        }
    } catch (e) {
        console.log("Error while parsing date")
    }
}


export const roles = {
    pentest_manager: "Pentest Manager",
    pentest_overseer: "Pentest Overseer",
    asset_poc: "Point Of Contact"
}

export const reportNames = {
    asset_final_report: "Asset Final Report",
    pentest_draft_final_report: "Pentest Draft Final Report",
    asset_draft_final_report: "Asset Draft Final Report",
    asset_status_report: "Asset Status Report",
    pentest_status_report: "Pentest Status Report",
    asset_executive_report: "Asset Executive Report",
    pentest_final_report: "Asset Final Report",
    pentest_security_audit_certificate: "Pentest Security Audit Certificate Report",
    asset_engagement_letter: "Asset Engagement Letter Report",
    pentest_executive_report: "Pentest Executive Report",
    pentest_asset_security_audit_certificate: "Asset Security Audit Certificate Report",
    genpt_pdf_report: "Autonomous Vulnerability Test Report",
    easm_csv_report: "EASM CSV Report",
    monitoring_csv_report: "Monitoring CSV Report",
    pentest_custom_findings_report: "Pentest Custom Finding Report",
    asset_custom_findings_report: "Asset Custom Findings Report"
}