import React from 'react';
import {useImage} from "../../hooks/misc";

const Image = ({src, type, findingId = null, assetId = null, conversationId = null, ...rest}) => {
    let url = useImage(src, type, true, findingId, conversationId, assetId);
    return (
        <img src={url} {...rest} alt=""/>
    );
};

export default Image;
