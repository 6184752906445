import React from "react";
import { Link } from "react-router-dom";
import { assetStages } from "../../../utils/constants/asset";
import { findingStatus } from "../../../utils/constants/findings";
import { reportNames, roles } from "./notification-helper";

const InviteUser = ({ notification, mainUrl, onLinkClick }) => {
    const pentestId = notification?.pentest?.id;
    const pentestName = notification?.pentest?.name;
    const customerLeaderName = notification?.init_user?.first_name;
    const inviteeName = notification?.to_user?.first_name;
    const inviteeRole = roles[notification?.role];
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> invited <span className="capitalize">{inviteeName}</span> to <span className="text-primary-500">{pentestName}</span> as a {inviteeRole}
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline" to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PentestCreated = ({ notification, mainUrl, onLinkClick }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> created a pentest, <span className="text-primary-500">{pentestName}</span>. Please complete pre-requisites to proceed with the test.
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline" to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PentestDrafted = ({ notification }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> started creating a new pentest <span className="text-primary-500">{pentestName}</span> and is saved in Draft
            </span>
        </>
    )
}

const PentestDraftedDeleted = ({ notification }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> deleted <span className="text-primary-500">{pentestName}</span> in Draft Pentest
            </span>
        </>
    )
}

const PentestAssetAdded = ({ notification, mainUrl, onLinkClick }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> added an asset, <span className="text-primary-500">{assetName}</span> to pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}


const PentestAssetDeleted = ({ notification, mainUrl, onLinkClick }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> deleted an asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PentestManagerAdded = ({ notification, mainUrl, onLinkClick }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const managerName = notification?.to_user?.first_name;
    const pentestId = notification?.pentest_id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> added <span className="text-primary-500">{managerName}</span> to pentest <span className="text-primary-500">{pentestName}</span> as a Pentest Manager
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}


const PentestManagerRemoved = ({ notification, mainUrl, onLinkClick }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const managerName = notification?.to_user?.first_name;
    const pentestId = notification?.pentest_id;
    return (
        <>
            <span className="font-normal text-base text-black">
            <span className="capitalize">{customerLeaderName}</span> removed Pentest Manager <span className="text-primary-500">{managerName}</span> from pentest <span className="text-primary-500">{pentestName}</span> 
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const AssetPoCAssigned = ({ notification, mainUrl, onLinkClick }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    const assignee = notification?.to_user?.first_name;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> assigned <span className="capitalize">{assignee}</span> as a Point of Contact to asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}
                onClick={onLinkClick}>
                {pentestName}
            </Link>
        </>
    )
}

const AssetPoCRemoved = ({ notification, mainUrl, onLinkClick }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    const assignee = notification?.to_user?.first_name;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> removed <span className="text-primary-500">Point of Contact</span> <span className="capitalize">{assignee}</span> from asset, <span className="text-primary-500">{assetName}</span> in <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}
                onClick={onLinkClick}>
                {pentestName}
            </Link>
        </>
    )
}

const AssetDeleteRequest = ({ notification, mainUrl, onLinkClick }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> requested the deletion of asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const AssetUpdated = ({ notification }) => {
    const userName = notification?.init_user?.first_name;
    const assetName = notification?.asset?.name;
    const companyName = notification?.company?.company_name
    let changedAttribute = notification?.category_type
    const fromValue = notification?.from_value ? notification?.from_value : "NA"
    const toValue = notification?.to_value ? notification?.to_value : "NA"

    switch (changedAttribute) {
        case 'name':
            changedAttribute = "name"
            break;
        case 'num_of_user_roles':
            changedAttribute = "No. of user roles"
            break;                
        case 'num_of_dynamic_pages':
            changedAttribute = "Approx. No. of dynamic pages"
            break;
        case 'department':
            changedAttribute = "department"
            break;
        case 'priority':
            changedAttribute = "priority"
            break;
        case 'num_of_end_points':
            changedAttribute = "Approx. No. of end points"
            break;
        case 'type_of_os':
            changedAttribute = "type of OS"
            break;
        case 'num_of_mobile_screens':
            changedAttribute = "Approx. No. of Mobile Screens"
            break;
        case 'num_of_external_ips':
            changedAttribute = "Approx. No. of External IPs"
            break;
        case 'num_of_internal_ips':
            changedAttribute = "Approx. No. of Internal IPs"
            break;
        default:
            break;
    }

    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{userName}</span> changed the {changedAttribute} of asset, <span className="text-primary-500">{assetName}</span> from <span className="text-primary-500">{fromValue}</span> to <span className="text-primary-500">{toValue}</span>
            </span>
            <p className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline ">
                {companyName}
            </p>
        </>
    )
}

const FillPreRequest = ({ notification, mainUrl, onLinkClick }) => {
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                Please fill the Pre-requisites sheet for the asset, <span className="text-primary-500">{assetName}</span> of pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PreRequestShared = ({ notification, mainUrl, onLinkClick }) => {
    const customerLeaderName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{customerLeaderName}</span> shared the Pre-requisites sheet of asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PreRequestComment = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> commented on a question in Pre-requisites sheet of asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PreRequestApproved = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> accepted the Pre-requisites sheet of asset, <span className="text-primary-500">{assetName}</span> from <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PentestStatusChanged = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> changed the status of the pentest <span className="text-primary-500">{pentestName}</span> from <span className="text-primary-500">{notification?.from_value}</span> to <span className="text-primary-500">{notification?.to_value}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline" to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const AssetCompletionChanged = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> completed <span className="text-primary-500">{notification?.to_value}%</span> of the testing for asset, <span className="text-primary-500">{assetName}</span> from <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const AssetAssigned = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    const assignee = notification?.to_user?.first_name;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> assigned <span className="capitalize">{assignee}</span> as a Pentester to asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}
                onClick={onLinkClick}>
                {pentestName}
            </Link>
        </>
    )
}

const AssetUnassigned = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    const assignee = notification?.to_user?.first_name;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> unassigned <span className="capitalize">{assignee}</span> as a <span className="text-primary-500">Pentester</span> to asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}
                onClick={onLinkClick}>
                {pentestName}
            </Link>
        </>
    )
}

const UserStoryAdded = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> added User Stories to an asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " 
                to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}
                onClick={onLinkClick}>
                {pentestName}
            </Link>
        </>
    )
}

const UserStoryEdited = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> edited the User Stories of the asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const BlockerAdded = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> added a blocker to asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const BlockerStatusUpdated = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> changed the Status of a blocker from <span className="text-primary-500 capitalize">{notification?.from_value}</span> to <span className="text-primary-500 capitalize">{notification?.to_value}</span> of asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const BlockerEdited = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> edited a blocker of asset, <span className="text-primary-500">{assetName}</span> from <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const LatestStatusAdded = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> added a Latest Status of asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PotentialFindingAdded = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> added a Potential Finding for QA to asset, <span className="text-primary-500">{assetName}</span> from <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const OpenFindingAdded = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> added a new Finding to asset, <span className="text-primary-500">{assetName}</span> from <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const FindingStatusUpdated = ({ notification, mainUrl, onLinkClick }) => {
    const userName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{userName}</span> changed the Status of a Finding from <span className="text-primary-500">{findingStatus[notification?.from_value]?.title}</span> to  <span className="text-primary-500">{findingStatus[notification?.to_value]?.title}</span> of asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const QualysFindingStatusUpdated = ({ notification, mainUrl, onLinkClick }) => {
    const userName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                The status of the finding for asset <span className="text-primary-500">{notification?.asset?.name}</span> was updated from <span className="text-camel-case text-primary-500">{notification?.from_value}</span> to <span className="text-camel-case text-primary-500">{notification?.to_value.split("-").join(" ")}</span> by the <span className="text-camel-case">{userName}</span>.
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const FindingEdited = ({ notification, mainUrl, onLinkClick }) => {
    const userName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{userName}</span> edited a Finding of asset, <span className="text-primary-500">{assetName}</span> from <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const FinalReportGenerated = ({ notification, mainUrl, onLinkClick }) => {
    const userName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const assetName = notification?.pentest_asset?.name;
    const assetId = notification?.pentest_asset?.id; 
    const pentestId = notification?.pentest?.id;
    const report = notification?.report;
    const sessionId = notification?.session_id;
    const target_url = notification?.target_url;

    return (
        <>
        {assetId ? (
            <span className="font-normal text-base text-black">
                <span className="capitalize">{userName}</span> generated the {reportNames[report?.report_type]} of asset, <span className="text-primary-500">{assetName}</span> from pentest <span className="text-primary-500">{pentestName}</span>
            </span>
        ) : pentestId ? (
            <span className="font-normal text-base text-black">
                <span className="capitalize">{userName}</span> generated the {reportNames[report?.report_type]} of pentest <span className="text-primary-500">{pentestName}</span>
            </span>
        ) : sessionId ? (
            <span className="font-normal text-base text-black">
                <span className="capitalize">{userName}</span> generated the {reportNames[report?.report_type]} for <span className="text-primary-500">{target_url}</span>.
            </span>
        ) : (
            <span className="font-normal text-base text-black">
                <span className="capitalize">{userName}</span> generated the {reportNames[report?.report_type]}.
            </span>
        )}
            {pentestId && <Link 
                onClick={onLinkClick} 
                className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline" 
                to={`${mainUrl}/all_pentests/${pentestId || ''}/dashboard`}
            >
                {pentestName || 'View Details'}
            </Link>
}
        </>
    );
};


const AssetStatusChanged = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const assetName = notification?.pentest_asset?.name;
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> changed the status of the asset, <span className="text-primary-500">{assetName}</span> of <span className="text-primary-500">{pentestName}</span> from <span className="text-primary-500">{assetStages[notification?.from_value]?.title}</span> to <span className="text-primary-500">{assetStages[notification?.to_value]?.title}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline" to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PentstCompletionChanged = ({ notification, mainUrl, onLinkClick }) => {
    const testerName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{testerName}</span> completed <span className="text-primary-500">{notification?.to_value}%</span> of the testing of pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`} 
                >
                {pentestName}
            </Link>
        </>
    )
}

const PentestNextIteration = ({ notification, mainUrl, onLinkClick }) => {
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                The next iteration of the Quarterly pentest, <span className="text-primary-500">{pentestName}</span> has been created
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const FillPrereqReminder = ({ notification, mainUrl, onLinkClick }) => {
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    const assetName = notification?.pentest_asset?.name;
    return (
        <>
            <span className="font-normal text-base text-black">
                Please fill the Pre-requisites sheet for the asset, <span className="text-primary-500">{assetName}</span> of pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const FillPrereqRequest = ({ notification, mainUrl, onLinkClick }) => {
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    const assetName = notification?.pentest_asset?.name;
    return (
        <>
            <span className="font-normal text-base text-black">
                Please fill the Pre-requisites sheet for the asset, <span className="text-primary-500">{assetName}</span> of pentest <span className="text-primary-500">{pentestName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline " to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const ImportedAssets = ({ notification, mainUrl, onLinkClick }) => {
    const userName = notification?.init_user?.first_name;
    const count = notification?.imported_assets_count;
    const companyName = notification?.company_details?.company_name
    return (
        <>
            <span className="font-normal text-base text-black">
                <span className="capitalize">{userName}</span> imported <span className="text-primary-500">{count}</span> asset(s).
            </span>
            <span className="block text-xs mt-1.5 !text-secondary-900 font-semibold ">
                {companyName}
            </span>
        </>
    );
}

const DeleteAssetsNotification = ({ notification, mainUrl, onLinkClick }) => {
    const userName = notification?.init_user?.first_name + (notification?.init_user?.last_name ? notification?.init_user?.last_name : "")
    const companyName = notification?.company?.company_name;
    const assetName = notification?.asset?.name;
    return (
        <>
            <span className="font-normal text-base text-black">
            <span className="text-primary-500">{userName}</span> has deleted an asset, <span className="text-primary-500">{assetName}</span>
            </span>
            <p className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline ">
                {companyName}
            </p>
        </>
    )
}

const DecommissionAssetsNotification = ({ notification, mainUrl, onLinkClick }) => {
    const userName = notification?.init_user?.first_name + (notification?.init_user?.last_name ? notification?.init_user?.last_name : "")
    const companyName = notification?.company?.company_name;
    const assetName = notification?.asset?.name;
    return (
        <>
            <span className="font-normal text-base text-black">
            <span className="text-primary-500">{userName}</span> has decommissioned an asset, <span className="text-primary-500">{assetName}</span>
            </span>
            <p className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline ">
                {companyName}
            </p>
        </>
    )
}

const RecommissionAssetsNotification = ({ notification, mainUrl, onLinkClick }) => {
    const userName = notification?.init_user?.first_name + (notification?.init_user?.last_name ? notification?.init_user?.last_name : "")
    const companyName = notification?.company?.company_name;
    const assetName = notification?.asset?.name;
    return (
        <>
            <span className="font-normal text-base text-black">
            <span className="text-primary-500">{userName}</span> has recommissioned an asset, <span className="text-primary-500">{assetName}</span>
            </span>
            <p className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline ">
                {companyName}
            </p>
        </>
    )
}

const DefaultNotification = () => {  
    return (
        <>
            <span className="font-normal text-base text-black !pt-3 h-[25px]">
                Notification type is missing!
            </span>
        </>
    )
}

const PentestIterationUpdatedOnNotification = ({ notification, mainUrl, onLinkClick }) => {
    const updaterName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
                The auto-iteration of the pentest <span className="text-primary-500">{pentestName}</span> was toggled ON by <span className="capitalize">{updaterName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline" to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const PentestIterationUpdatedOffNotification = ({ notification, mainUrl, onLinkClick }) => {
    const updaterName = notification?.init_user?.first_name;
    const pentestName = notification?.pentest?.name;
    const pentestId = notification?.pentest?.id;
    return (
        <>
            <span className="font-normal text-base text-black">
            The auto-iteration of the pentest <span className="text-primary-500">{pentestName}</span> was toggled OFF by <span className="capitalize">{updaterName}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline" to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const FindingCustomerAssigned = ({ notification, mainUrl, onLinkClick }) => {
    const updaterName = notification?.init_user?.first_name;
    const findingTitle = notification?.finding?.title;
    const pentestId = notification?.pentest?.id;
    const pentestName = notification?.pentest?.name;
    const assetTitle = notification?.pentest_asset?.name;
    const toCustomer = notification?.to_user?.first_name
    return (
        <>
            <span className="font-normal text-base text-black">
            <span className="">{updaterName}</span> has assigned a finding <span className="capitalize text-primary-500">{findingTitle}</span> of asset <span className="capitalize text-primary-500">{assetTitle}</span> to <span className="capitalize text-primary-500">{toCustomer}</span>
            </span>
            <Link onClick={onLinkClick} className="block text-xs mt-1.5 !text-secondary-900 font-semibold hover:underline" to={`${mainUrl}/all_pentests/${pentestId}/dashboard`}>
                {pentestName}
            </Link>
        </>
    )
}

const NotifyPrerequisites = ({ notification }) => {
    const assetName = notification?.pentest_asset?.name;
    return (
        <>
            <span className="font-normal text-base text-black">
            Please fill in the prerequisites form of the asset <span className="capitalize text-primary-500">{assetName}</span>, to start the testing.
            </span>
        </>
    )
}



const NotificationComponents = {
    FINDING_CUSTOMER_ASSIGNED:FindingCustomerAssigned,
    INVITE_USER: InviteUser,
    PENTEST_CREATED: PentestCreated,
    PENTEST_DRAFTED: PentestDrafted,
    PENTEST_DRAFT_DELETED: PentestDraftedDeleted,
    PENTEST_ASSET_ADDED: PentestAssetAdded,
    PENTEST_MANAGER_ADDED: PentestManagerAdded,
    PENTEST_MANAGER_REMOVED: PentestManagerRemoved,
    ASSET_POC_ASSIGNED: AssetPoCAssigned,
    ASSET_POC_REMOVED: AssetPoCRemoved,
    PENTEST_ASSET_DELETED: PentestAssetDeleted,
    ASSET_DELETE_REQUEST: AssetDeleteRequest,
    FILL_PRE_REQUEST: FillPreRequest,
    PRE_REQUEST_SHARED: PreRequestShared,
    PRE_REQUEST_COMMENT: PreRequestComment,
    PRE_REQUEST_APPROVED: PreRequestApproved,
    PENTEST_STATUS_CHANGED: PentestStatusChanged,
    ASSET_COMPLETION_CHANGED: AssetCompletionChanged,
    ASSET_ASSIGNED: AssetAssigned,
    ASSET_UNASSIGNED: AssetUnassigned,
    ASSET_UPDATED : AssetUpdated,
    USER_STORY_ADDED: UserStoryAdded,
    USER_STORY_EDITED: UserStoryEdited,
    BLOCKER_ADDED: BlockerAdded,
    BLOCKER_STATUS_UPDATED: BlockerStatusUpdated,
    BLOCKER_EDITED: BlockerEdited,
    LATEST_STATUS_ADDED: LatestStatusAdded,
    POTENTIAL_FINDING_ADDED: PotentialFindingAdded,
    OPEN_FINDING_ADDED: OpenFindingAdded,
    FINDING_STATUS_UPDATED: FindingStatusUpdated,
    QUALYS_FINDING_STATUS_UPDATED: QualysFindingStatusUpdated,
    FINDING_EDITED: FindingEdited,
    FINAL_REPORT_GENERATED: FinalReportGenerated,
    ASSET_STATUS_CHANGED: AssetStatusChanged,
    PENTEST_COMPLETION_CHANGED: PentstCompletionChanged,
    PENTEST_NEXT_ITERATION: PentestNextIteration,
    DEFAULT: DefaultNotification,
    FILL_PREREQ_REMINDER: FillPrereqReminder,
    FILL_PREREQ_REQUEST: FillPrereqRequest,
    IMPORTED_ASSETS: ImportedAssets,
    DELETE_ASSETS: DeleteAssetsNotification,
    DECOMMISSION_ASSETS: DecommissionAssetsNotification,
    RECOMMISSION_ASSETS: RecommissionAssetsNotification,
    PENTEST_ITERATION_UPDATED_ON: PentestIterationUpdatedOnNotification,
    PENTEST_ITERATION_UPDATED_OFF: PentestIterationUpdatedOffNotification,
    NOTIFY_PREREQUISITES: NotifyPrerequisites
}

export default NotificationComponents;