import axios from "axios";

const instance = axios.create({});
instance.defaults.headers.common = {};

export const generatePreSignedUrl = async (data) => {
    let res = await axios.post(`misc/s3/get_presigned_url`, data)
    return res.data;
}

export const uploadFile = (options) => {
    let {category, file, type: mediaType, from} = options
    return new Promise(async (resolve, reject) => {
        try {
            let {name: file_name, type, size} = file
            let payload = {
                category,
                file_name,
                file_type: type.split("/").pop(),
                mediaType,
                size
            }
            let res = await generatePreSignedUrl(payload)
            let preSignedURL = res?.signedUrl

            // Create a canvas element to remove EXIF data
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();

            img.onload = async () => {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);

                canvas.toBlob(async (blob) => {
                    await instance.put(preSignedURL, blob);
                    resolve(res?.key);
                }, file.type);
            };

            img.onerror = (error) => {
                reject(error);
            };

            const reader = new FileReader();
            reader.onload = () => {
                img.src = reader.result;
            };
            reader.readAsDataURL(file);
        } catch (e) {
            if(from === 'PoC' && e?.response?.data?.message)
                resolve ({success: false, message: e?.response?.data?.message})
            else
                reject(e)
        }
    })
}

