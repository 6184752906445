import React, { useEffect } from "react";
import {Routes, Route, useMatch} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import ClipPath from "../components/ClipPath";
import store from "../redux/store";
import {QueryClient, QueryClientProvider} from "react-query";
import {Provider} from "react-redux";
import {
    AssetPoc,
    ConnectSiemba,
    Home,
    PentestProfile,
    PentestReview,
    PentestScope,
    PentestTeam,
    Profile,
    UpdatePentest,
    SSOCallback
} from "./pages/PageListAsync";
import AdminRoutes from "./Routes/AdminRoutes";
import AlertProvider from "../components/SuccessOrFailedModal";
import MyDashboardRoutes from "./Routes/MyDashboardRoutes";
import TesterRoutes from "./Routes/TesterRoutes";
import moment from "moment";
import LayoutProvider from "../Providers/LayoutProvider";
import { AMPLITUDE_API_KEY } from "../utils/constants";

// Create a client
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            refetchOnWindowFocus: false
        }
    }
})

moment.updateLocale('en', {
    relativeTime : {
        future: "in %s",
        past:   "%s ago",
        s  : '1s',
        ss : '%ds',
        m:  "1m",
        mm: "%dm",
        h:  "1h",
        hh: "%dh",
        d:  "1d",
        dd: "%dd",
        M:  "1mon",
        MM: "%dmon",
        y:  "1y",
        yy: "%dy"
    }
});

function App() {

    let customLayout = false
    if (useMatch("admin/all_pentests/:pentestId/update")){
        customLayout = true
    }

    useEffect(() => {
        // Retrieve the Amplitude API key from the environment variables
        const amplitudeAPIKey = AMPLITUDE_API_KEY;

        // Only proceed if the Amplitude API key is present and not empty
        if (!amplitudeAPIKey) {
            console.warn("Amplitude API key is not set. Skipping Amplitude initialization.");
            return;
        }
      
        // Step 1: Dynamically create a script element for Amplitude Analytics library
        const analyticsScript = document.createElement("script");
        analyticsScript.src = "https://cdn.amplitude.com/libs/analytics-browser-2.11.1-min.js.gz"; // URL for the Amplitude Analytics library
        analyticsScript.async = true; // Load script asynchronously to avoid blocking the rendering
        document.body.appendChild(analyticsScript); // Append the script to the body of the document
      
        // Step 2: Dynamically create a script element for the Session Replay plugin
        const sessionReplayScript = document.createElement("script");
        sessionReplayScript.src = "https://cdn.amplitude.com/libs/plugin-session-replay-browser-1.8.0-min.js.gz"; // URL for the Session Replay plugin
        sessionReplayScript.async = true; // Load script asynchronously for optimal performance
        document.body.appendChild(sessionReplayScript); // Append the script to the body of the document
      
        // Step 3: Initialize Amplitude and Session Replay after both scripts are loaded
        analyticsScript.onload = () => {
          // Ensure that the Analytics library is fully loaded
          sessionReplayScript.onload = () => {
            // Ensure that the Session Replay plugin is fully loaded
            if (window.amplitude && window.sessionReplay) {
              // Check if the required Amplitude objects are available
              // Add the Session Replay plugin with a 100% sampling rate
              window.amplitude.add(window.sessionReplay.plugin({ sampleRate: 1 }));
      
              // Initialize the Amplitude Analytics instance with the API key
              // Configure it to enable autocapture for element interactions
              window.amplitude.init(amplitudeAPIKey, {
                autocapture: { elementInteractions: true },
              });
            }
          };
        };
      }, []);      

    return (
        <>
        { !customLayout ? ( 
            <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <AlertProvider>
                    <Header/>
                    <ClipPath/>
                    <Routes>
                        <Route path={"/"} element={<Home/>}/>
                        <Route path={"/:uuid/sso-callback"} element={<SSOCallback/>}/>
                        <Route path={"profile"} element={<Profile/>}/>
                        <Route path={"/connect_siemba"} element={<ConnectSiemba/>}/>
                        <Route path={"/pentest"}>
                            <Route path={"profile"} element={<PentestProfile/>}/>
                            <Route path={"scope"} element={<PentestScope/>}/>
                            <Route path={"team"} element={<PentestTeam/>}/>
                            <Route path={"team_overseers"} element={<PentestTeam/>}/>
                            <Route path={"team_asset_poc"} element={<AssetPoc/>}/>
                            <Route path={"review"} element={<PentestReview/>}/>
                        </Route>
                        <Route path={"/admin/*"} element={<AdminRoutes/>}/>
                        <Route path={"/app/*"} element={<MyDashboardRoutes/>}/>
                        <Route path={"/tester/*"} element={<TesterRoutes/>}/>
                    </Routes>
                    <Footer/>
                </AlertProvider>
            </Provider>
        </QueryClientProvider>) : (
            <QueryClientProvider client={queryClient}>
                <Provider store={store}>
                    <AlertProvider>
                        <ClipPath/>
                        <Routes>
                            <Route path={"/admin/all_pentests/:pentest_id/update"} element={
                                <LayoutProvider value={{
                dashboard: "ADMIN_DASHBOARD",
                mainUrl: "/admin"}}
                                ><UpdatePentest/></LayoutProvider>
                            }/>
                        </Routes>
                    </AlertProvider>
                </Provider>
            </QueryClientProvider>
        )}
        </>
       
    );
}

export default App;
