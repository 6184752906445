import {useQuery} from "react-query";
import {getImage, getTemplate} from "../api/queries/misc";
import { FINDINGS_STATS } from "./utils/constants";
import axios from "axios";
import { paginationAttr } from "../actions/commonActions";
import { useEffect } from "react";

export const useImage = (key, type = null, url = true, findingId = null, conversationId = null, assetId = null ) => {
    let queryKey = ['image', key, type, findingId, conversationId, assetId];

    let res = useQuery(queryKey, getImage, {
        staleTime: 1000,
        enabled: Boolean(key),
    });
    if (url) return res?.data
    return res;
}

export const useTemplate = (key, type, url = true) => {
    let res = useQuery([key, type], getTemplate, {
        staleTime: 900000
    });

    if (url) {
        return res?.data;
    }

    return res;
};


export const useMasterLists = (options = {}) => {
    const {
        type = null,
        searchKey = null,
        showAll = false
    } = options
    return useQuery([FINDINGS_STATS, type, searchKey, showAll], async () => {
        let res = await axios.get(`misc/master/master_lists/${paginationAttr({
            type, searchKey, showAll
        })}`)
        return res.data;
    }, {
        ...options
    })
}