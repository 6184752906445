import CryptoJS from 'crypto-js';

const setData = (name, value, session = false) => {
    if (session)
        sessionStorage.setItem(name, value)
    else
        localStorage.setItem(name, value)
}

const getData = (name, session = false) => {
    if (session)
        return sessionStorage.getItem(name)
    return localStorage.getItem(name)
}
const removeData = (name, session = false) => {
    if (session)
        sessionStorage.removeItem(name)
    else
        localStorage.removeItem(name)
}

const setToken = (token) => {
    setData("jwt.siemba", token)
}

const setEasm = (value) => {
    setData("easm_enabled", value)
}

const setGenpt = (value) => {
    setData("genpt_enabled", value)
}


const clear = (session = false) => {
    if (session)
        sessionStorage.clear()
    else
        localStorage.clear()
}

// Helper function to decrypt the data
const decryptData = (encryptedData, key) => {
    const [ivBase64, encryptedValue] = encryptedData.split(':');
    const iv = CryptoJS.enc.Base64.parse(ivBase64);
    const encrypted = CryptoJS.enc.Base64.parse(encryptedValue);
    const keyHex = CryptoJS.enc.Hex.parse(key);
  
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encrypted },
      keyHex,
      { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
    );

    return decrypted.toString(CryptoJS.enc.Utf8); // Convert to boolean
  }


const decryptStorageData = (name) => {
    const encryptedData = getData(name)
    const encryptionKey = CryptoJS.SHA256(getData("jwt.siemba")).toString(CryptoJS.enc.Hex);
    const decryptedData = decryptData(encryptedData, encryptionKey)
    return decryptedData;
}

const Storage = {
    setData,
    getData,
    token: getData("jwt.siemba"),
    setToken,
    clear,
    removeData,
    setEasm,
    setGenpt,
    decryptStorageData
}

export default Storage;
