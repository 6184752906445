export const tooltipTitle = {
    assetRiskLevel: "Asset Risk Level is determined using the DREAD methodology and is informed by the score of the riskiest open vulnerability identified for that asset.",
    enterpriseRiskLevel: "Enterprise Risk Level is determined using the DREAD methodology and is informed by the score of the riskiest asset in your scope.",
    // finding
    dread: "DREAD framework can be used to evaluate and triage various threats by rating them on an ordinal scale broken into five main categories: Damage, Reproducibility, Exploitability, Affected Users, and Discoverability. To know more, Link to Knowledge repo...",
    dread_score: "Enter a value from 0 to 10 for each criteria",
    cvss: "The Common Vulnerability Scoring System (CVSS) is the industry standard for assessing the severity of computer system security vulnerabilities. To know more, Link to Knowledge repo...",
    likelihood: "Likelihood of a vulnerability refers to the ease with which a vulnerability could be exploited. To know more, Link to Knowledge repo...",
    severity: "Severity of a vulnerability is determined by the damage a vulnerability could cause to the organisation if successfully exploited. To know more, Link to Knowledge repo...",
    //onboard
    pentestStartDate: "Enter the date by which you would like us to commence  testing. Please note that this date is dependent on availability of the complete pre requisite sheets at the least 3 days before the start date proposed. ",
    pentestFrequency: "Choosing the appropriate frequency of the pentest will streamline your workflow of recurring assessments in the ProVAPT Platform. \n" +
        "\n" +
        "> Initial - First pentest initiated as a one-time assessment.\n" +
        "> Release Based - Next iteration of the assessment can be manually started by you.\n" +
        "> Quarterly - Automatically restarts every 3 months.\n" +
        "> Bi-Annual - Automatically restarts every 6 months.\n" +
        "> Annual - Automatically restarts yearly",
    pentestDepartment: "Enter a Department Name to easily identify the pentest.",
    pentestVersion: "Initial/Release Based - Enter the version number to track conducted pentests. \n" +
        "For Automated Frequencies, this field is auto-populated.",
    noOfUserRoles: "Enter the total number of User Roles  to be tested. Example: Admin, Manager, User, etc.",
    noOfDynamicPages: "The approximate number of dynamic pages to be tested in the asset. A dynamic page displays different content for different users while retaining the same layout and design.",
    department: "Enter the department you want this asset to be a part of.", // used in add assets
    asset_criticality: "Asset criticality is the ranking of assets based on their importance to the operation, function, or success of an organization based on Confidentiality, Availability and Integrity. \n \n 5 options available are:\nMission Critical \nBusiness Critical \nSignificant Impact \nLimited Impact \nNegligible Impact", // used in add assets

    //reports
    noOfEndPoints: "The approximate number of API endpoints to be tested.",
    typeOfOS: "Mention the type of mobile OS here (Android, iOS, etc.). Add a separate row for each type.",
    noOfMobileScreen: "The approximate number of screens to be tested.",
    noOfExternalIPs: "The approximate number of publicly available IPs to be tested.",
    noOfInteralIPs: "The approximate number of internal (Non public facing) IPs to be tested.",
    noOfUniqueServices: "The approximate number of unique services to be tested in each account. Example: For AWS, there are EC2, S3,Cloudtrail,etc.",

    asset_status_report: "A Status Report consists the latest information at that point of time regarding the asset.",
    asset_executive_report: "An Executive Report is a summarized report of the final status of the asset. This tab will be active only when the whole security assessment is complete.",
    asset_final_report: "A Final Report is a concise report generated after the completion of the security assessment. The Final Report is the proof that a security assessment has been completed on the asset, during auditing and compliance.",
    pentest_asset_security_audit_certificate: "This document certifies that a security assessment was conducted  on the asset and its risk level after the completion of the process.",
    pentest_final_report: "A Final Report is a concise report generated after the completion of the security assessment. The Final Report is the proof that a security assessment has been completed on the asset(s), during auditing and compliance.",
    pentest_executive_report: "An Executive Report is a summarized report of the final status of all the assets in the pentest. This tab will be active only when the whole security assessment is complete.",
    asset_engagement_letter: "Engagement Letter is a document of confirmation that Siemba has been engaged by you for the security assessment of the asset(s) in the pentest. This document will be used for certifications.",
    pentest_status_report: "A Status Report consists the latest information at that point of time regarding the pentest.",
    //asset dashboard
    prerequisites: "To commence testing, it is mandatory that all requested information in the prerequisite sheets is complete.",
    testing: "During this stage, an assigned tester conducts the security pentest on the assets in scope and will be marked as done after successful completion.",
    remediation: "Once testing is complete and recommended remediation procedure is made available, you are expected to remediate the found vulnerabilities. Once the remediation is done, it is revalidated to ensure that all the fixes are in place at this stage.",
    finalReport: "Once all the findings are resolved either with a recommended fix or a business justification, the final report could be generated and this would mark the completion of the pentest engagement.",
    multipleAssets: "Note that the same asset might be counted multiple times if it is involved in multiple pentests.",
    //asset_criticality
    mission_critical: "\u2022 Disruption or loss of these assets could cause severe consequences: financial losses, safety hazards, environmental damage, and reputational harm.\n \u2022 Likely holds highly sensitive data (high impact on Confidentiality).\n \u2022 Supports essential business functions (high impact on Integrity).\n \u2022 Vital for overall organizational security and stability (high impact on Availability).",
    business_critical: "\u2022 Important for organizational success and stability; not as critical as mission-critical assets. \n \u2022 Their disruption would significantly hinder operations, causing financial losses or reputational damage. \n \u2022 Likely holds moderately sensitive information (moderate to high impact on Confidentiality). \n \u2022 Supports vital business processes (moderate to high impact on Integrity). \n \u2022 Essential for daily operations (moderate to high impact on Availability).",
    significant_impact: "\u2022 Important for efficiency; not directly linked to core mission or financial stability. \n \u2022 Disruption could cause inconvenience and delays, impacting productivity, but could be mitigated without major consequences. \n \u2022 Likely holds non-critical information (moderate impact on Confidentiality), supports specific business functions (moderate impact on Integrity). \n \u2022 Essential for smooth workflow (moderate impact on Availability).",
    limited_impact: "\u2022 Minimal impact on overall operations; easily replaceable or mitigated. \n \u2022 Disruption causes inconvenience without major impacts on productivity or finances. \n \u2022 Likely holds publicly available information (low impact on Confidentiality). \n \u2022 Has limited impact on business processes (low to moderate impact on Integrity). \n \u2022 Noticeable absence but not detrimental (low to moderate impact on Availability).",
    negligible_impact: "\u2022 Virtually no impact on the organization; could go unnoticed if lost. \n \u2022 Loss would have no impact on operations or finances. \n \u2022 Contains non-sensitive or publicly available information (low impact on Confidentiality). \n \u2022 No effect on business processes (low impact on Integrity). \n \u2022 Absence would be undetectable (low impact on Availability).",

    //genpt
    bulk_test_declaration: "I acknowledge and accept the legal and compliance aspects related to conducting these tests. \n \n This is a mandatory step to proceed with the test initiation.",

    //domain
    grade: {
        "a+": `All Grade A requirements plus additional security features and includes HSTS preloading.\n
                Recommendation: Ensure HSTS is properly configured and preloaded.`,
        "a": `Highest security level with strong ciphers, TLS 1.2+, proper certificates, no deprecated features.\n
                Recommendation: Maintain current configuration, regularly update and review security settings.`,
        "b": `Minor issues, relatively secure like use of TLS 1.0/1.1, some weaker ciphers, lacks some advanced features.\n
                Recommendation: Disable TLS 1.0/1.1, use only strong ciphers, enable advanced security features.`,
        "c": `Significant issues, moderate security. Supports deprecated protocols (e.g., SSL 3.0), weak ciphers like RC4, minor certificate issues.\n
                Recommendation: Disable SSL 3.0 and weak ciphers, update certificates, enforce stronger protocols.`,
        "d": `Serious security problems like very weak ciphers, critical configuration issues, significant vulnerabilities.\n
                Recommendation: Disable very weak ciphers, resolve critical configuration issues, update and patch server.`,
        "e": `Severe security issues like broken protocols and ciphers, major certificate issues, high risk.\n
                Recommendation: Replace broken protocols and ciphers, fix major certificate issues, enhance overall security posture.`,
        "f": `Fatal security flaws like no encryption, null ciphers, invalid certificates, extremely insecure.\n
                Recommendation: Implement encryption, replace null ciphers, ensure valid certificates, overhaul security settings.`,
        "t": `Indicates that the site's certificate is not trusted.\n
                Recommendation: Investigate why the certificate is not trusted. Ensure the certificate is issued by a recognized Certificate Authority and properly installed.`,
        "m": `Certificate hostname does not match the domain name.\n
                Recommendation: Ensure that the certificate's common name (CN) matches the domain name of the server`
    },

    all_grades: `
        A+: All Grade A requirements plus additional security features and includes HSTS preloading.\n
        A: Highest security level with strong ciphers, TLS 1.2+, proper certificates, no deprecated features.\n
        B: Minor issues, relatively secure like use of TLS 1.0/1.1, some weaker ciphers, lacks some advanced features.\n
        C: Significant issues, moderate security. Supports deprecated protocols (e.g., SSL 3.0), weak ciphers like RC4, minor certificate issues.\n
        D: Serious security problems like very weak ciphers, critical configuration issues, significant vulnerabilities.\n
        E: Severe security issues like broken protocols and ciphers, major certificate issues, high risk.\n
        F: Fatal security flaws like no encryption, null ciphers, invalid certificates, extremely insecure.\n
        T: Indicates that the site's certificate is not trusted.\n
        M: Certificate hostname does not match the domain name.\n
        No Data: Indicates that no information was returned by our data sources.
    `,
    risk_score: "This score is determined by combining the weighted values of key parameters—discovery source, asset criticality, severity, and likelihood—each with specific weights, to provide a comprehensive measure of overall finding risk.",
    no_data: '"No Data” indicates that no information was returned by our data sources.'


}