import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { CHAT_TEAM, CONVERSATIONS, INBOX } from "./utils/constants";
import axios from "axios";
import moment from "moment";
import { DATE_FORMAT } from "../utils/constants";

export const useChatTeam = ({type, search = "", enabled = true}) => {
    return useInfiniteQuery([CHAT_TEAM, {type, search}], async ({pageParam = 1}) => {
        let res = await axios.get(`chat/team?page=${pageParam}&type=${type}&search=${search || ""}`)
        return res.data
    }, {
        getNextPageParam: lastPage => lastPage?.nextPage ?? undefined,
        keepPreviousData: true,
        enabled
    })
}

export const useConversations = ({type, enabled = true, search}) => {
    search = search || ''
    return useInfiniteQuery([CONVERSATIONS, {type, search}], async ({pageParam = 1}) => {
        let res = await axios.get(`chat/conversations?page=${pageParam}&type=${type}&search=${search}`)
        return res.data
    }, {
        getNextPageParam: lastPage => lastPage?.nextPage ?? undefined,
        enabled
    })
}

//mutations
export const useCreateConversation = () => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.post('chat/conversations', data)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(CONVERSATIONS)
            await queryClient.invalidateQueries(CHAT_TEAM)
        }
    })
}

export const useUpdatePentestConversation = (pentestId) => {
    const queryClient = useQueryClient()
    return useMutation(async (data) => {
        let res = await axios.post(`chat/${pentestId}/update_pentest_conversations`, data)
        return res.data
    }, {
        onSuccess: async () => {
            await queryClient.invalidateQueries(CONVERSATIONS)
            await queryClient.invalidateQueries(CHAT_TEAM)
        }
    })
}


export const useInbox = (conversation_id , callHook = true) => {
    const queryClient = useQueryClient()
    return useInfiniteQuery([INBOX, conversation_id], async ({pageParam = 1}) => {
        let res = await axios.get(`chat/inbox/${conversation_id}?page=${pageParam}`)
        let date = null
        const messages = res?.data?.messages?.map(message => {
            let messageDate = moment(message?.timestamp).format(DATE_FORMAT)
            if (date && date !== messageDate) {
                message.showDate = date
            }
            date = messageDate
            const user = res?.data?.userObj?.[message?.userId]
            message.authorUser = {
                ...user,
                uuid: message?.userId,
            }
            return message
        })
        const data = {
            ...res?.data,
            messages
        }
        queryClient.setQueryData([CONVERSATIONS, {type: res?.data?.conversation?.is_group ? "channels" : "personal", search: ""}], (oldData) => {
            oldData?.pages.forEach(page => {
                // find conversation
                const found = page?.conversations?.find(el => el?._id === conversation_id)
                if (found) {
                    found.unReadCount = 0
                }
            })
            return oldData
        })
        queryClient.invalidateQueries(CONVERSATIONS)
        return data

    }, {
        getNextPageParam: lastPage => lastPage?.nextPage ?? undefined,
        enabled: !!conversation_id && callHook
    })
}
