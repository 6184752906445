import React, { useContext, useState } from 'react';
import AddIcon from "@mui/icons-material/Add";
import Button from "../Button";
import Typography from "../Typography";
import LogoutIcon from "../SvgIcons/LogoutIcon";
import { useDispatch } from "react-redux";
import { setAddCustomerModal } from "../../redux/reducers/admin/customer";
import { useLogout, useValidateLogin } from "../../hooks/auth";
import { Link, useLocation, useMatch, useNavigate } from "react-router-dom";
import { setAddFindingsModal, setAddUsersModal, setInviteCustomerModal, setNewPentestModal, setUpdatePentestLandscape, setImportAssetsModal, setNewEASMModal, setSubscribeModal, setSetupVAModal, setGenptSubscribeModal, setEASMSubscribeModal, setEasmSubscribeModal } from "../../redux/reducers/dashboard";
import EditIcon from "../SvgIcons/EditIcon";
import { useCreateFindings } from "../../hooks/findings";
import MainLoader from "../Loader/MainLoader";
import SlackIcon from "../SvgIcons/SlackIcon";
import { SLACK_CLIENT_ID } from "../../utils/constants";
import { usePentestById } from '../../hooks/pentest';
import { useAssetById } from '../../hooks/asset';
import Storage from '../../utils/Storage';
import EASMIconGrey from '../SvgIcons/EASMIconGrey';
import VAIconGrey from '../SvgIcons/VALogoGrey';
import SiembaGPTIconGrey from '../SvgIcons/GPTIconGrey';
import SiembaGPTIconTheme from '../SvgIcons/GPTIconTheme';
import ShieldIcon from '@mui/icons-material/Shield';
import EASMIconTheme from '../SvgIcons/EASMIconTheme';
import VAIconTheme from '../SvgIcons/VALogoTheme';
import { SIEMBA_ADMIN } from '../../utils/roles';

export const AddCustomer = (props) => {
    const { className } = props
    const dispatch = useDispatch()
    return (
        <Button onClick={() => dispatch(setAddCustomerModal(true))}
            fullWidth={true} size={"small"} variant={"contained"} className={className}><AddIcon /> Add
            Customer</Button>
    );
};
export const ImportAssets = (props) => {
    const dispatch = useDispatch()
    const { className, companyId } = props
    const { pathname } = useLocation()
    const isAdmin = pathname?.startsWith("/admin/asset_management");
    return (
        <>
            {
                isAdmin &&
                <Button onClick={() => {
                    dispatch(setImportAssetsModal({
                        open: true,
                        companyId: companyId
                    }))
                    }} fullWidth={true} size={"small"} variant={"outlined"}
                        className={className}>Import
                    Assets</Button>
            }
        </>

    );
};

export const AddFindings = (props) => {
    const { className } = props
    const dispatch = useDispatch()
    const { pathname } = useLocation()
    const isAdmin = pathname?.startsWith("/admin")
    return (
        <Button onClick={() => dispatch(setAddFindingsModal({ open: true }))}
            fullWidth={true} size={"small"} variant={"contained"}
            className={`${isAdmin && "!bg-secondary-700"} ${className}`}><AddIcon /> Add
            Findings</Button>
    );
};

export const AddPartner = (props) => {
    const { className } = props
    return (
        <Button fullWidth={true} size={"small"} variant={"contained"}
            className={`!bg-secondary-700 ${className}`}><AddIcon /> Add Partner</Button>
    );
};

export const StartNewPentest = (props) => {
    const dispatch = useDispatch()
    const [colorChange, setColorChange] = useState(false);
    const { className, label } = props
    const { pathname } = useLocation()
    const isAdmin = pathname?.startsWith("/admin");
    let isDashboard = pathname?.startsWith("/app") || isAdmin
    return (
        <>
            {
                isDashboard &&
                <div 
                    className='flex flex-align-item-center pl-1' 
                    onClick={() => {
                        dispatch(setNewPentestModal({
                            open: true,
                            progress: isAdmin ? "pentest_company" : "pentest_profile",
                            companyId: props?.companyId,
                            companyName: props?.companyName,
                        }))
                    }}
                    onMouseEnter={() => setColorChange(true)}
                    onMouseLeave={() => setColorChange(false)}
                >
                    <ShieldIcon htmlColor={ colorChange ? '#12AFB8' : '#666'} className='sheild-icon' />
                    <Button size={"small"}
                        className={`bg-transparent ${ colorChange ? 'primary-color' : 'text-grey' } !p-0 ml-2 ${className}`}>{label ? 'Pentest' : 'Pentest'}</Button>
                </div>
            }
        </>

    );
};

export const StartNewEASM = (props) => {
    const dispatch = useDispatch()
    const [colorChange, setColorChange] = useState(false);
    const { className, label, redirection=false } = props
    const { pathname } = useLocation()
    const isAdmin = pathname?.startsWith("/admin");
    let isDashboard = pathname?.startsWith("/app") || isAdmin;

    const startEasm = () => {
        let easm_enabled = Storage.decryptStorageData('easm_enabled') === 'true';
        if(easm_enabled){
            dispatch(setNewEASMModal({
                open: true,
                redirection,
                companyId: props?.companyId,
                companyName: props?.companyName,
            }))
        }else{
            dispatch(setEasmSubscribeModal({
                open: true,
            }))
        }
    }

    return (
        <>
            {
                isDashboard &&
                    props?.isEASM ?
                        <Button onClick={() => {
                            startEasm()
                        }} fullWidth={true} size={"small"} variant={"contained"}
                            className={`!bg-primary-500 ${className}`}>{label ? label : 'EASM'}</Button>
                    :
                        <div 
                            className='flex flex-align-item-center' 
                            onClick={() => {
                                startEasm()
                            }} 
                            onMouseEnter={() => setColorChange(true)}
                            onMouseLeave={() => setColorChange(false)}
                        >
                            {
                                colorChange ?
                                    <EASMIconTheme />
                                :
                                    <EASMIconGrey />
                            }
                            <Button size={"small"}
                                className={`bg-transparent ${ colorChange ? 'primary-color' : 'text-grey' } !p-0 ml-3 ${className}`}>{label ? label : 'EASM'}</Button>
                        </div>
            }
        </>

    );
};

export const StartNewGenPT = (props) => {
    const [colorChange, setColorChange] = useState(false);
    const { data } = useValidateLogin();
    let genpt_enabled = Storage.decryptStorageData('genpt_enabled') === 'true';
    const { className, label, from } = props;
    const { pathname } = useLocation();
    const isAdmin = pathname?.startsWith("/admin");
    let isDashboard = pathname?.startsWith("/app") || isAdmin;
    const navigate = useNavigate();
    const mainUrl = data?.user?.user_type == 'provider' ? '/admin' : '/app';
    const dispatch = useDispatch()
    const startGenpt = () => {
        if(genpt_enabled){
            navigate(`${mainUrl}/genpt/tests/start`);
            window.location.reload();
        }else{
            dispatch(setGenptSubscribeModal({
                open: true,
                from: from
            }))
        }
    }

    return (
        <>
            {
                isDashboard &&
                <div 
                    className='flex flex-align-item-center pl-1' 
                    onClick={() => {
                        startGenpt()
                    }}
                    onMouseEnter={() => setColorChange(true)}
                    onMouseLeave={() => setColorChange(false)}
                >
                    {
                        colorChange ?
                            <SiembaGPTIconTheme />
                        :
                            <SiembaGPTIconGrey />
                    }
                    <Button size={"small"}
                        className={`bg-transparent ${ colorChange ? 'primary-color' : 'text-grey' } ml-2 !p-0 ${className}`}>{label ? 'GenPT' : 'GenPT'}</Button>
                </div>
            }
        </>

    );
};

export const StartNewVA = (props) => {
    const [colorChange, setColorChange] = useState(false);
    const { data } = useValidateLogin();
    const dispatch = useDispatch()
    const mainUrl = data?.user?.user_type == 'provider' ? '/admin' : '/app';
    const navigate = useNavigate();
    const openSubscribeModal = () => {
        if(data?.user?.user_type == 'provider'){
            navigate(`${mainUrl}/all_va`)
            window.location.reload();
        }else{
            if(!data.user?.va_subscribed){       
                dispatch(setSubscribeModal({
                    open: true,
                }))
            }else{
                dispatch(setSetupVAModal({
                    open: true,
                }))
            }
        }
    }
    const { className, label } = props
    const { pathname } = useLocation();
    const isAdmin = pathname?.startsWith("/admin");
    let isDashboard = pathname?.startsWith("/app") || isAdmin;
    return (
        <>
            {
                isDashboard &&
                <div 
                    className='flex flex-align-item-center' 
                    onClick={() => openSubscribeModal()}
                    onMouseEnter={() => setColorChange(true)}
                    onMouseLeave={() => setColorChange(false)}
                >
                    {
                        colorChange ?
                            <VAIconTheme />
                        :
                            <VAIconGrey />
                    }
                    <Button size={"small"}
                    className={`bg-transparent ${ colorChange ? 'primary-color' : 'text-grey' } !p-0 ml-2 ${className}`}>{label ? 'Vul. Assessment' : 'Vul. Assessment'}</Button>
                </div>
            }
        </>

    );
};




export const AddUsers = (props) => {
    const dispatch = useDispatch()
    const { className } = props
    const { pathname } = useLocation()
    const isAdmin = pathname?.startsWith("/admin/user_management");
    return (
        <>
            {
                isAdmin &&
                <Button onClick={() => {
                    dispatch(setAddUsersModal({
                        open: true,
                    }))
                }} fullWidth={true} size={"small"} variant={"contained"}
                        className={`${isAdmin && "!bg-secondary-700"} ${className}`}><AddIcon /> Add
                        Users</Button>
            }
        </>

    );
};

export const InviteCustomer = (props) => {
    const dispatch = useDispatch()
    const { className } = props
    const { pathname } = useLocation()
    let isDashboard = pathname?.startsWith("/app")
    return (
        <>
            {
                isDashboard &&
                <Button onClick={() => {
                    dispatch(setInviteCustomerModal({
                        open: true
                    }))
                }} fullWidth={true} size={"small"} variant={"contained"}
                    className={`!bg-secondary-700 ${className}`}><AddIcon /> Add New User</Button>
            }
        </>
    );
};

export const SeeScope = (props) => {
    const location = useMatch("/app/all_assets/:id/dashboard")
    const asset_id = location?.params?.id
    const { className } = props
    return (
        <>
            {
                asset_id &&
                <Link to={`/app/all_assets/${asset_id}/edit_scope`}>
                    <Button fullWidth={true} size={"small"} variant={"contained"}
                        className={`!bg-secondary-700 ${className}`}><EditIcon
                            pathProps={{
                                className: "fill-white",
                                style: {
                                    fillOpacity: 1
                                }
                            }}
                        /> See/Edit Scope</Button>
                </Link>
            }
        </>
    );
};

export const AddFindingsToThisAsset = (props) => {
    const testerPath = useMatch("/tester/all_assets/:id/dashboard")
    const adminPath = useMatch("/admin/all_assets/:id/dashboard")
    const location = testerPath || adminPath
    const asset_id = location?.params?.id;
    let { isFetching, data: assetData } = useAssetById(asset_id)
    let asset = assetData?.pentestAsset?.asset
    let platform_category = asset?.platform_category
    const { className } = props
    const mutation = useCreateFindings()
    const dispatch = useDispatch()
    const openAddFindingsModal = async (platform_category) => {
        let payload = {
            pentest_asset_id: asset_id,
            finding_data: {
                is_draft: true
            }
        }
        let res = await mutation.mutateAsync(payload)
        dispatch(setAddFindingsModal({
            open: true,
            asset_id: asset_id,
            finding_id: res.id,
            platform_category: platform_category
        }))
    }
    return (
        <>
            {mutation?.isLoading && <MainLoader />}
            {
                asset_id &&
                    <></>
                // <Button onClick={() => openAddFindingsModal(platform_category)} fullWidth={true} size={"small"} variant={"contained"}
                //     className={`!bg-primary-400 ${className}`} addIcon={true}> Add Findings To This Asset</Button>
            }
        </>
    );
};
export const UpdatePentestLandScapeButton = (props) => {
    const customerPath = useMatch("/app/all_pentests/:id/dashboard")
    const adminPath = useMatch("/admin/all_pentests/:id/dashboard")
    const location = customerPath || adminPath;
    const pentest_id = location?.params?.id;
    const {data: userData} =  useValidateLogin();
    const isSiembaAdmin = userData?.user?.role == SIEMBA_ADMIN;
    let {isFetching, data} = usePentestById(pentest_id);
    const { className } = props
    const dispatch = useDispatch()
    const openUpdatePentestLandscapeModal = () => {
        dispatch(setUpdatePentestLandscape(true))
    }
   return (
        <>
            {
                customerPath && pentest_id && data && data.pentest.stage !== "closed" &&
                <Button onClick={openUpdatePentestLandscapeModal} fullWidth={false} size={"small"} variant={"contained"}
                    className={`!bg-secondary-700 ${className}`}><EditIcon
                        pathProps={{
                            className: "fill-white",
                            style: {
                                fillOpacity: 1
                            }
                        }}
                    /> Update Pentest Landscape</Button>
            }{ 
                adminPath && pentest_id && (isSiembaAdmin || data?.status !== "completed") &&
                <Link to={`/admin/all_pentests/${pentest_id}/update`} className={{className}}>
                    <Button fullWidth={true} size={"small"} variant={"contained"}
                        className={`!bg-secondary-700 ${className}`}>
                        <EditIcon
                            pathProps={{
                                className: "fill-white",
                                style: {
                                    fillOpacity: 1
                                }
                            }} /> Update Pentest Landscape
                    </Button>
                </Link>
            }
        </>
    );
};
export const MyTeam = (props) => {
    const { className } = props
    return (
        <Link to={"/admin/team"}>
            <Button size={"small"} fullWidth={true} variant={"outlined"} className={className}>My Team</Button>
        </Link>
    );
};

export const SlackButton = (props) => {
    const { className } = props
    const slackSharableUrl = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=channels:manage,channels:read,chat:write,chat:write.public,users:read,users:read.email,groups:write&user_scope=`
    const { pathname } = useLocation()
    let isDashboard = pathname?.startsWith("/app")
    return (
        <>
            {
                isDashboard &&
                <a href={slackSharableUrl}>
                    <Button size={"small"} fullWidth={true} variant={"contained"}
                        className={`${className} !bg-[#4A154B]`}><SlackIcon />Link With Slack</Button>
                </a>
            }
        </>
    );
};

export const Account = (props) => {
    const { data } = useValidateLogin()
    return (
        <>
            <Typography variant={"caption"} className={"text-[10px]"}>Signed In As</Typography>
            <Typography variant={"caption"}
                className={"text-secondary-700 mt-1"}>{data?.user?.email}</Typography>
        </>
    )
}
export const Logout = (props) => {
    const logout = useLogout()
    return (
        <div className={"cursor-pointer"}>
            <Typography onClick={() => logout.mutate()} variant={"caption"} className={"font-semibold"}><LogoutIcon
                className={"text-body"} />&nbsp;Sign Out</Typography>
        </div>
    )
}

