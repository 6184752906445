import axios from "axios";

export const getImage = async ({queryKey}) => {
    let key = queryKey[1]
    let type = queryKey[2]
    let findingId = queryKey[3]
    let conversationId = queryKey[4]
    let assetId = queryKey[5]
    let url = `/misc/s3/get_presigned_url?key=${encodeURIComponent(key)}&type=${type}`;
    
    // If findingId is provided, add it to the query string
    if (findingId) {
        url += `&findingId=${encodeURIComponent(findingId)}`;
    }
    if (conversationId) {
        url += `&conversationId=${encodeURIComponent(conversationId)}`;
    }
    if(assetId){
        url += `&assetId=${encodeURIComponent(assetId)}`;
    }

    // Make the request
    let res = await axios.get(url);
    return res.data?.signedUrl;
}

export const getTemplate = async ({ queryKey }) => {
    let key = queryKey[0]
    let type = queryKey[1]
    if (key) {
        let res = await axios.get(`/misc/s3/get_presigned_url?key=${key}&type=${type}`)
        return res.data?.signedUrl;
    }
}
